/**
 * Pilote App - Entry point file
 * @author Hervé Perchec
 * @description
 * Main entry function (IIFE)
 * We have to load runtime configuration before running app (main.js):
 * - loads the runtime configuration json (fetch)
 * - loads the 'main.js' app file
 */

import * as JSONC from 'jsonc-parser'

(async function () {
  try {
    const response = await fetch(__RUNTIME_CONFIG_PATH__, {
      cache: 'no-store'
    })
    if (!response.ok) {
      throw new Error(`[${__GLOBALS__.APP_NAME}] Unable to load runtime configuration dependency: ${__RUNTIME_CONFIG_PATH__}`)
    }
    const runtimeConfig = await response.text()
    window.__APP_RUNTIME_CONFIG__ = JSONC.parse(runtimeConfig)
  } catch (err) {
    console.error(err)
  }
  /**
   * Here the webpack magic comment
   * will generate bundle app.X.X.X.js
   */
  await import(/* webpackChunkName: "app" */ './main.js')
})()
